import { default as aboutcGvMViY0SnMeta } from "/opt/buildhome/repo/pages/about.vue?macro=true";
import { default as articlesrUijrSIq5yMeta } from "/opt/buildhome/repo/pages/articles.vue?macro=true";
import { default as contactupLWW5VsuQMeta } from "/opt/buildhome/repo/pages/contact.vue?macro=true";
import { default as helpbRqkzSrYBJMeta } from "/opt/buildhome/repo/pages/help.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
export default [
  {
    name: aboutcGvMViY0SnMeta?.name ?? "about",
    path: aboutcGvMViY0SnMeta?.path ?? "/about",
    meta: aboutcGvMViY0SnMeta || {},
    alias: aboutcGvMViY0SnMeta?.alias || [],
    redirect: aboutcGvMViY0SnMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/about.vue").then(m => m.default || m)
  },
  {
    name: articlesrUijrSIq5yMeta?.name ?? "articles",
    path: articlesrUijrSIq5yMeta?.path ?? "/articles",
    meta: articlesrUijrSIq5yMeta || {},
    alias: articlesrUijrSIq5yMeta?.alias || [],
    redirect: articlesrUijrSIq5yMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/articles.vue").then(m => m.default || m)
  },
  {
    name: contactupLWW5VsuQMeta?.name ?? "contact",
    path: contactupLWW5VsuQMeta?.path ?? "/contact",
    meta: contactupLWW5VsuQMeta || {},
    alias: contactupLWW5VsuQMeta?.alias || [],
    redirect: contactupLWW5VsuQMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: helpbRqkzSrYBJMeta?.name ?? "help",
    path: helpbRqkzSrYBJMeta?.path ?? "/help",
    meta: helpbRqkzSrYBJMeta || {},
    alias: helpbRqkzSrYBJMeta?.alias || [],
    redirect: helpbRqkzSrYBJMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/help.vue").then(m => m.default || m)
  },
  {
    name: indexniDiYCWjuTMeta?.name ?? "index",
    path: indexniDiYCWjuTMeta?.path ?? "/",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  }
]