
import { defuFn } from '/opt/buildhome/repo/node_modules/defu/dist/defu.mjs'

const inlineConfig = {
  "ui": {
    "primary": "green",
    "gray": "cool",
    "colors": [
      "red",
      "orange",
      "amber",
      "yellow",
      "lime",
      "green",
      "emerald",
      "teal",
      "cyan",
      "sky",
      "blue",
      "indigo",
      "violet",
      "purple",
      "fuchsia",
      "pink",
      "rose",
      "primary"
    ]
  }
}

import cfg0 from "/opt/buildhome/repo/app.config.ts"
import cfg1 from "/opt/buildhome/repo/node_modules/@nuxthq/ui/dist/runtime/app.config.mjs"

export default /* #__PURE__ */ defuFn(cfg0, cfg1, inlineConfig)
